import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import {checkAuth} from "../../helpers/auth";
import Results from '../../components/DashboardResults';
import ReportsFiltering from "../../components/ReportsFiltering";
import axios from "axios";
import moment from 'moment';
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";

class ProductSales extends React.Component {
    results = React.createRef();
    state = {
        config: null,
        selectedDimensions: {
            dimension1: 'Transaction Date',
            dimension2: 'Product',
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        },
        additionalFilters: {
            "Include internal traffic sources": true
        }
    }
    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/product-sales`).then(response => {
            this.setState({
                ...this.state,
                config: response.data
            })
        })
        
    }
    onSelect(selected, additionalFilters) {
        this.setState({
            ...this.state,
            selectedDimensions: selected,
            additionalFilters: additionalFilters
        }, () => {
            this.results.current.loadData();
        })
    }

    getDimensionParams() {
        let str = '';
        for(let i in this.state.selectedDimensions) {
            if(typeof(this.state.selectedDimensions[i]) !== 'undefined') {
                if(typeof(this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }
        return str;
    }

    getFilterParams() {
        let str = '';
        if(Object.keys(this.state.additionalFilters).length > 0) {
            for(let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }
    getColumns() {
        let cols = Object.assign([], this.state.config.columns);
        cols.unshift(this.state.selectedDimensions.dimension2);
        cols.unshift(this.state.selectedDimensions.dimension1);
        return cols;
    }

    setHiddenCols(hiddenCols) {
        let config = {...this.state.config};
        config.defaultHiddenColumns = hiddenCols;
        this.setState({config: config});
    }

    render() {
        let cols = [];
        if(this.state.config !== null) {
            cols = this.getColumns();
        }

        let groupingCols = [];
        [this.state.selectedDimensions.dimension1, 
            this.state.selectedDimensions.dimension2, 
        ].map(d => d ? groupingCols.push(d) : false)

        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        {this.state.config !== null ? 
                            <div className="dashboardGroup__switch" style={style.tabContainer}>
                                <Results
                                    ref={this.results}
                                    columns={cols}
                                    url={`${process.env.SERVER_ENDPOINT}/reports/product-sales?${this.getDimensionParams() }${this.getFilterParams()}`}
                                    title={this.state.config.title}
                                    groupingOptions={{
                                        cols: groupingCols,
                                        startingRow: 0
                                    }}
                                    defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                    sort={{
                                        column: 'Transaction Date',
                                        asc: false
                                    }}
                                    exportCSVConfig={{
                                        name:'product-sales',
                                        url: `${process.env.SERVER_ENDPOINT}/reports/product-sales?${this.getDimensionParams() }${this.getFilterParams()}`
                                    }}
                                    pagination={true}
                                    columnsFormat={this.state.config.columnsFormat}
                                    filters={{
                                        selected: this.state.selectedDimensions, 
                                        additionalFilters: this.state.additionalFilters
                                    }}
                                    dimensions={this.state.config.dimensions}
                                    onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }

                                >
                                    <h3>Product Sales by Day</h3>
                                    <ReportsFiltering
                                        columns={cols}
                                        defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                        onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }
                                        onSelect={(selected, additionalFilters) => this.onSelect(selected, additionalFilters)}
                                        selected={{
                                            dimension1: 'Transaction Date',
                                            dimension2: 'Product',
                                            startDate: this.state.selectedDimensions.startDate,
                                            endDate: this.state.selectedDimensions.endDate
                                        }}
                                        type="transactions"
                                        config={this.state.config}
                                    />
                                </Results>
                                <ScrollTopLeft />
                            </div>
                        : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default ProductSales;
